<template>
  <span style="margin-right: 5px;" :key="tooth" class="tooth" @click="emitClick">🦷 {{ currentNorm === 'fdi' ? tooth : fdiToUns(tooth) }}</span>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Tooth extends Vue {
  @Prop({default: 18}) tooth: number | undefined;

  mounted() {
    // on change on Vue.prototype.$state.norm
    this.$root.$on('norm', (norm: string) => {
      console.log('norm', norm);
    });
  }

  get currentNorm() {
    return Vue.prototype.$state.norm;
  }

  emitClick() {
    this.$emit('click', this.tooth);
  }

  fdiToUns(tooth: string | number) {
    const map = {
      18: 1,
      17: 2,
      16: 3,
      15: 4,
      14: 5,
      13: 6,
      12: 7,
      11: 8,
      21: 9,
      22: 10,
      23: 11,
      24: 12,
      25: 13,
      26: 14,
      27: 15,
      28: 16,
      38: 17,
      37: 18,
      36: 19,
      35: 20,
      34: 21,
      33: 22,
      32: 23,
      31: 24,
      41: 25,
      42: 26,
      43: 27,
      44: 28,
      45: 29,
      46: 30,
      47: 31,
      48: 32,
    };

    // @ts-ignore
    return map[tooth];
  }
}
</script>

<style scoped>
.tooth {
  border: 2px solid white;
  background: #865CA9;
  border-radius: 20px;
  color: white;
  padding: 2px 8px 2px 6px;
  line-height: 20px;
  cursor: pointer;
  transition: background 0.3s;
}

.tooth:hover {
  background: #6C4C8C;
}
</style>