<template>
  <div class="container">
    <h3>Liste des calques</h3>
    <div class="sidebar">
      <ul>
        <li v-for="toRenderNode in nodes" :key="toRenderNode.id" :class="{ active: toRenderNode.id === selectedNodeId }" @click="selectNode(toRenderNode.id); checkSub(toRenderNode)">
          <img v-if="toRenderNode.hasOwnProperty('custom') && toRenderNode.custom.hasOwnProperty('image')" :src="toRenderNode.custom.image" alt="logo" />
          {{ toRenderNode.name }}
        </li>
      </ul>
    </div>
    <div class="sidebar sub" v-if="subSidebar.length > 0">
      <ul>
        <li v-for="toRenderNode in subSidebar" :class="{ active: toRenderNode.id === selectedNodeId }" :key="toRenderNode.id" @click="selectNode(toRenderNode.id);  checkSub(toRenderNode)">
          <img v-if="toRenderNode.hasOwnProperty('custom') && toRenderNode.custom.hasOwnProperty('image')" :src="toRenderNode.custom.image" alt="logo" />
          {{ toRenderNode.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
  @Prop({default: []}) nodes: { id: string, name: string, custom: { image: string } }[] | undefined;
  @Prop({default: ''}) selectedNodeId: string | undefined;

  private subSidebar: any[] = [];

  checkSub(node: any) {
    // check if there is at least one final_product in the subNodes (node.attributes.final_product = true)
    if (!node.subNodes || !node.subNodes.some((subNode: any) => subNode.custom.final_product)) {
      this.subSidebar = node.subNodes;
      this.$emit('renderFinalProducts', []);
    } else {
      this.subSidebar = [];

      if(node.subNodes) {
        this.$emit('renderFinalProducts', node.subNodes);
      } else {
        this.$emit('renderFinalProducts', []);
      }
    }
  }

  selectNode(nodeId: string) {
    this.$emit('selectNode', nodeId);
  }
}
</script>

<style scoped>
.container {
  /* vertical centering */
  display: flex;
  height: 100vh;
  flex-direction: column;
  font-family: Raleway, sans-serif;
  padding-top: 25px;
}

.sidebar {
  width: 250px;
  z-index: 1;
  background-color: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-left: 25px;
}

.container h3 {
  margin-left: 25px;
  width: 250px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  padding: 10px;
  cursor: pointer;
  text-align: left;
  border-bottom: 1px solid #E5E7E7;

  display: flex;
  align-items: center;

  transition: background-color 0.3s;
}

.sidebar ul li img {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  vertical-align: middle;
}

.sidebar ul li:hover {
  background-color: #f1f1f1;
}

.sidebar ul li.active {
  background-color: #E4E4E4;
}

.sidebar.sub {
  position: absolute;
  left: 250px;
  top: 85px;
}
</style>