(function() {
    // Création de la barre de navigation
    const navbar = document.createElement('nav');
    navbar.style.cssText = `
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #6A4985;
        padding: 15px 0;
        z-index: 1000;
        box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    `;

    // Création du conteneur pour centrer les onglets
    const container = document.createElement('div');
    container.style.cssText = `
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 800px;
        margin: 0 auto;
    `;

    // Création des onglets
    const tabs = [
        { name: 'Builder', url: 'https://builder.plibeo-dev-gamma.com' },
        { name: 'Viewer', url: 'https://viewer.plibeo-dev-gamma.com' },
        { name: 'Compatibilities', url: 'https://compatibilities.plibeo-dev-gamma.com' },
        { name: 'Graphics', url: 'https://graphics.plibeo-dev-gamma.com' }
    ];

    // Fonction pour vérifier si un onglet est actif
    function isTabActive(tabUrl) {
        return window.location.href.includes(tabUrl);
    }

    // Ajout des onglets au conteneur
    tabs.forEach(tab => {
        const link = document.createElement('a');
        link.href = tab.url;
        link.textContent = tab.name;
        link.style.cssText = `
            color: #ecf0f1;
            text-decoration: none;
            padding: 10px 20px;
            margin: 0 5px;
            font-family: Arial, sans-serif;
            font-size: 16px;
            transition: all 0.3s ease;
            border-radius: 5px;
        `;

        // Vérification si l'onglet est actif
        if (isTabActive(tab.url) || tab.name === 'Graphics') {
            link.style.backgroundColor = '#865CA9';
            link.style.color = '#ffffff';
        }

        link.addEventListener('click', function(e) {
            e.preventDefault();
            // Supprimer l'état actif de tous les onglets
            container.querySelectorAll('a').forEach(a => {
                a.style.backgroundColor = 'transparent';
                a.style.color = '#ecf0f1';
            });
            // Ajouter l'état actif à l'onglet cliqué
            this.style.backgroundColor = '#865CA9';
            this.style.color = '#ffffff';
            window.location.href = this.href;
        });

        link.addEventListener('mouseover', function() {
            if (!isTabActive(this.href)) {
                this.style.backgroundColor = '#865CA9';
            }
        });

        link.addEventListener('mouseout', function() {
            if (!isTabActive(this.href)) {
                this.style.backgroundColor = 'transparent';
            }
        });

        container.appendChild(link);
    });

    // Ajout du conteneur à la barre de navigation
    navbar.appendChild(container);

    // Ajout de la barre de navigation au début du body
    document.body.insertBefore(navbar, document.body.firstChild);

    // add position: relative to #app, with top: 68px (in a style tag)
    const style = document.createElement('style');
    style.textContent = `
        #app {
            position: relative;
            top: 68px;
        }
    `;
    document.head.appendChild(style);
})();