<template>
  <div>
    <div class="products sub" v-if="subSubProducts.length > 1">
      <div class="product" v-for="product in subSubProducts" :key="product.id" :class="{ active: product.id === selectedProductId }" @click="selectedProduct(product)">
        <img :src="product.custom.image" :alt="product.name" v-if="product.custom.image" />
        <p>{{product.name}}</p>
      </div>
    </div>
    <div class="products sub" v-if="subProducts.length > 1">
      <div class="product" v-for="product in subProducts" :key="product.id" @click="selectedProduct(product); checkSubSub(product)" :class="{ active: product.id === selectedProductId }">
        <img :src="product.custom.image" :alt="product.name" v-if="product.custom.image" />
        <p>{{product.name}}</p>
      </div>
    </div>
    <div class="products">
      <div class="product" v-for="product in products" :key="product.id" @click="selectedProduct(product); checkSub(product)" :class="{ active: product.id === selectedProductId }">
        <img :src="product.custom.image" :alt="product.name" />
        <p>{{product.name}}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class Products extends Vue {
  @Prop({default: []}) products: { id: string, name: string, custom: { image: string } }[] | undefined;
  @Prop({default: ''}) selectedProductId: string | undefined;

  private subProducts: any[] = [];
  private subSubProducts: any[] = [];

  @Watch('products')
  onProductsChange() {
    this.subProducts = [];
    this.subSubProducts = [];
  }

  checkSub(product: any) {
    if(!product.custom.final_product) {
      if(product.subNodes) {
        this.subProducts = product.subNodes;
      } else {
        this.subProducts = [];
      }
    } else {
      this.subProducts = [];
    }

    this.subSubProducts = [];
  }

  checkSubSub(product: any) {
    if(!product.custom.final_product) {
      if(product.subNodes) {
        this.subSubProducts = product.subNodes;
      } else {
        this.subSubProducts = [];
      }
    } else {
      this.subSubProducts = [];
    }
  }

  selectedProduct(product: any) {
    if(product.custom.final_product) {
      Vue.prototype.$state.productSelected = product;
      this.$emit('selectedProduct', product.id);
    } else {
      Vue.prototype.$state.productSelected = null;
      this.$emit('selectedProduct', '');
    }
  }
}
</script>

<style scoped>
.products {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  transition: all 0.3s;
}

.product {
  display: flex;
  margin: 5px;
  padding: 5px;
  text-align: center;
  width: 200px;
  flex-direction: row;
  border-radius: 10px;
  background: #FFFFFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-right: 10px;
  cursor: pointer;
  align-items: center;
}

.product img {
  width: 40px;
  height: 40px;
  border-radius: 8px;
}

.product p {
  margin: 0;
  display: flex;
  align-items: center;
  font-family: Raleway, sans-serif;
  font-size: 16px;
  font-weight: 600;
  margin-left: 5px;
}

.product:hover {
  background: #F5F5F5;
}

.products.sub {
  border-bottom: 2px solid #6A4985;
  padding-bottom: 30px;
  zoom: 0.8;
}

.products.sub .product {
  background: #EEEEEE;
}

.product.active {
  outline: 2px solid #6A4985;
  filter: drop-shadow(0 0 10px rgba(106, 73, 133, 0.5));
}
</style>