import Vue from 'vue'
import App from './App.vue'
import New from './New.vue'

Vue.config.productionTip = false

Vue.prototype.$state = Vue.observable({
  norm: 'fdi',
  productSelected: null,
  globalMessage: '',
  currentWork: null,
  works: []
})

new Vue({
  render: h => h(New),
}).$mount('#app')
