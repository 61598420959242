<template>
  <div class="resume" style="padding-top: 10px;">
    <button @click="importSVG()" style="margin-bottom: 10px;margin-top: 10px;font-size: 14px;">Importer un calque de prévisualisation</button><br />
    <button @click="exportSVG()" style="margin-bottom: 10px;font-size: 14px;">Exporter le calque complet</button><br />
    <h4>Ordre des calques</h4>
    <div class="works">
      <div class="work" v-for="work in works" :key="work.workId" :class="{active: selectedWork.workId === work.workId}">
        <img :src="work.product.custom.image" alt="work" />
        <span>{{ work.product.name }}</span>
        <span style="float: right;text-align: right;" @click="setWork(work)" >❌</span>
        <div class="break"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Tooth from "@/components/Tooth.vue";

@Component({
  components: {
    Tooth
  }
})
export default class Resume extends Vue {
  get works() {
    return Vue.prototype.$state.works;
  }

  get selectedWork() {
    return Vue.prototype.$state.currentWork;
  }

  importSVG() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.svg';
    input.onchange = (e: any) => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const parser = new DOMParser();
        const svg = parser.parseFromString(e.target.result, 'image/svg+xml');
        const g = svg.querySelector('#Dents');
        if (!g) {
          alert("Calque de prévisualisation non trouvé");
          return;
        } else {
          // replace <svg> content with the imported svg (whole)
          const teethMap = document.querySelector('.teeth-map svg');
          if (teethMap) {
            teethMap.innerHTML = svg.documentElement.innerHTML;
          } else {
            alert("Calque de prévisualisation non trouvé");
          }
        }
      };
      reader.readAsText(file);
    };
    input.click();
  }

  exportSVG() {
    const svgElement = document.querySelector('.teeth-map svg');

    if (!svgElement) {
      console.error("Élément SVG non trouvé");
      return;
    }

    // Fonction pour vérifier si un élément est visible
    // @ts-ignore
    function isVisible(elem) {
      return true;
      if (!(elem instanceof Element)) return false;
      const style = getComputedStyle(elem);
      if (style.display === 'none') return false;
      if (style.visibility === 'hidden') return false;
      if (style.opacity === '0') return false;
      // @ts-ignore
      if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height +
          elem.getBoundingClientRect().width === 0) {
        return false;
      }
      return true;
    }

    // Fonction récursive pour nettoyer le SVG
    // @ts-ignore
    function cleanSVG(node) {
      // Exclure les éléments <pattern>
      if (node.tagName === 'pattern') return null;

      // Exclure les enfants de #Dents
      if (node.id === 'Dents') {
        const clone = node.cloneNode(false);
        // Copier uniquement les attributs de #Dents
        for (let attr of node.attributes) {
          clone.setAttribute(attr.name, attr.value);
        }
        return clone;
      }

      const clone = node.cloneNode(false);
      if (node.nodeType === 1) { // Element node
        if (!isVisible(node)) return null;
        for (let child of node.childNodes) {
          const cleanChild = cleanSVG(child);
          if (cleanChild) clone.appendChild(cleanChild);
        }
      } else {
        clone.textContent = node.textContent;
      }
      return clone;
    }

    // Nettoyer le SVG
    const cleanedSVG = (svgElement);

    if (!cleanedSVG) {
      console.error("Aucun élément visible dans le SVG");
      return;
    }

    // Obtenir le contenu SVG nettoyé
    const svgContent = new XMLSerializer().serializeToString(cleanedSVG);

    // Créer un Blob avec le contenu SVG
    const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });

    // Créer une URL pour le Blob
    const url = URL.createObjectURL(blob);

    // Créer un élément <a> pour le téléchargement
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'teeth-map.svg';

    // Ajouter temporairement le lien au document et cliquer dessus
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Nettoyer
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }
  exportBaseSVG() {
    const svgElement = document.querySelector('.teeth-map svg');

    if (!svgElement) {
      console.error("Élément SVG non trouvé");
      return;
    }

    // Fonction pour vérifier si un élément est visible
    // @ts-ignore
    function isVisible(elem) {
      if (!(elem instanceof Element)) return false;
      const style = getComputedStyle(elem);
      if (style.display === 'none') return false;
      if (style.visibility === 'hidden') return false;
      if (style.opacity === '0') return false;
      // @ts-ignore
      if (elem.offsetWidth + elem.offsetHeight + elem.getBoundingClientRect().height +
          elem.getBoundingClientRect().width === 0) {
        return false;
      }
      return true;
    }

    // Fonction récursive pour nettoyer le SVG
    // @ts-ignore
    function cleanSVG(node) {
      const clone = node.cloneNode(false);
      if (node.nodeType === 1) { // Element node
        if (!isVisible(node)) return null;
        for (let child of node.childNodes) {
          const cleanChild = cleanSVG(child);
          if (cleanChild) clone.appendChild(cleanChild);
        }
      } else {
        clone.textContent = node.textContent;
      }
      return clone;
    }

    // Nettoyer le SVG
    const cleanedSVG = cleanSVG(svgElement);

    if (!cleanedSVG) {
      console.error("Aucun élément visible dans le SVG");
      return;
    }

    // Obtenir le contenu SVG nettoyé
    const svgContent = new XMLSerializer().serializeToString(cleanedSVG);

    // Créer un Blob avec le contenu SVG
    const blob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });

    // Créer une URL pour le Blob
    const url = URL.createObjectURL(blob);

    // Créer un élément <a> pour le téléchargement
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = 'teeth-map.svg';

    // Ajouter temporairement le lien au document et cliquer dessus
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Nettoyer
    document.body.removeChild(downloadLink);
    URL.revokeObjectURL(url);
  }

  setWork(work: any) {
    //Vue.prototype.$state.currentWork = work;
    // remove work from works
    Vue.prototype.$state.works = Vue.prototype.$state.works.filter((w: any) => w.workId !== work.workId);
  }

  deleteTooth(tooth: string, workId: string) {
    Vue.prototype.$state.works = Vue.prototype.$state.works.map((work: any) => {
      if(work.workId === workId) {
        work.teeth = work.teeth.filter((t: string) => t !== tooth);
      }
      return work;
    });

    // remove work if no teeth left
    Vue.prototype.$state.works = Vue.prototype.$state.works.filter((work: any) => work.teeth.length > 0);
  }
}
</script>

<style scoped>
.resume {
  height: 100vh;
  background-color: #C7C7C7;
  font-family: Raleway, sans-serif;
  font-weight: 500;
  padding: 25px;
}

.works {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 450px);
}

.work {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #E5E7E7;
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  flex-wrap: wrap;
  padding: 15px;
}

.work img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid #000;
  flex: 0 0 30px;
  margin-right: 10px;
  cursor: pointer;
}

/* aligns the text with the image */
.work > span {
  flex: 1;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s;
}

.work > span:hover {
  color: #865CA9;
}

.teeth {
  display: flex;
  gap: 5px;
  margin-top: 5px;
  /* center horizontally */
  justify-content: center;
  text-align: center;
  width: 100%;
}

.break {
   flex-basis: 100%;
   height: 0;
 }

.options {
  display: flex;
  flex-basis: 100%;
  gap: 5px;
}

.options span {
  position: relative;
  flex: 1;
  font-weight: bold;
  padding: 5px 10px;
  background-color: #FFFFFF;
  border-radius: 10px;
  /* center text */
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.options span b {
  /* right align */
  position: absolute;
  right: 10px;
  cursor: pointer;
  transition: transform 0.3s;
}

.options span b:hover {
  transform: rotate(10deg);
}

.tooth {
  border: 2px solid white;
  background: #865CA9;
  border-radius: 20px;
  color: white;
  padding: 2px 8px 2px 6px;
  line-height: 20px;
  cursor: pointer;
  transition: background 0.3s;
}

.tooth:hover {
  background: #6C4C8C;
}

.plus {
  line-height: 28px;
  cursor: pointer;
  transition: transform 0.3s;
}

.plus:hover {
  /* rotate 5 degrees */
  transform: rotate(10deg);
}

.separator {
  height: 5px;
  width: 100%;
  background-color: #E5E7E7;
  margin-top: 30px;
  margin-bottom: 10px;
  border-radius: 10px;
}

.more {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

textarea {
  font-family: Raleway, sans-serif;
  border: 1px solid #865CA9;
  border-radius: 10px;
  padding: 15px;
  font-weight: 500;
  resize: none;
  background-color: #E5E7E7;
  font-size: 14px;
  outline: none !important;
}

button {
  font-family: Raleway, sans-serif;
  font-size: 18px;
  background-color: #865CA9;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 10px;
  font-weight: 700;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

button:hover {
  background-color: #6C4C8C;
}

.work.active {
  outline: 4px solid #865CA9;
}
</style>